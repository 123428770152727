import * as React from 'react';
import styled from '@emotion/styled';
import { GatsbyLinkProps, Link } from 'gatsby';
import { size } from './mq';
import { primary } from './colors';

interface BackgroundProps {
  image: string;
}

interface LinkProps extends GatsbyLinkProps<unknown> {}

interface Props extends LinkProps, BackgroundProps {
  name: string;
  logo: string;
}

const ImageLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  /* aspect-ratio: 2 / 1; */

  &:hover > .background-image,
  &:active > .background-image {
    transform: scale(1.1);

    &::before {
      display: block;
      background-color: rgba(0, 0, 0, 0.35);
    }
  }
`;

const BackgroundImage = styled.div`
  background-color: black;
  background-position: center;
  background-size: cover;
  background-image: url('${(props: BackgroundProps) => props.image}');
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: all 0.4s;

  &::before {
    content: '';
    transition: all 0.4s;
    /* display: none; */
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const LogoImage = styled.img`
  z-index: 1;
`;

export const GameLogoLink = (props: Props) => (
  <ImageLink to={props.to} className={props.className}>
    <BackgroundImage className="background-image" image={props.image} />
    <LogoImage src={props.logo} alt={props.name} />
  </ImageLink>
);
