/* @jsx jsx */
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { jsx } from '@emotion/react';

import SiteHeader from '@/components/SiteHeader';
import {
  HeroHeaderSection,
  HeroHeaderTitle,
  HeroHeaderSubtitle,
} from '@/components/HeroHeader';
import { Button } from '@/components/Button';
import { steamBlack } from '@/images/icons';
import { SiteFooter } from '@/components/SiteFooter';
import { Section } from '@/components/layout/Section';
import { Link } from 'gatsby';
import { size } from '@/components/mq';
import { primary } from '@/components/colors';
import { GameLogoLink } from '@/components/GameLogoLink';
import { logo, screenshot } from '@/images/double-turn';

const KnockoutButton = styled(Button)`
  color: #000;
  mix-blend-mode: screen;

  > img {
    margin-right: 6px;
  }

  &:hover,
  &:active {
    /* mix-blend-mode: normal; */
    /* background-color: #000; */
    background-color: #ededed;
    scale: 1.05;
    color: #000000;
    transition: 0.2s;
  }
`;

const AboutSection = styled(Section)`
  color: #ffffff;

  padding: 16px 0;
  ${size.medium} {
    padding: 48px 0;
  }

  h2 {
    margin: 0;
  }

  p {
    font-size: 16px;
    /* font-weight: bold;  */
    max-width: 600px;
    margin: 0 0 16px 0;

    ${size.medium} {
      font-size: 26px;
    }
  }
`;

const StrongUnderlineWrapper = styled.strong`
  font-weight: bold;
  position: relative;

  span {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 3px;
    ${size.medium} {
      height: 4px;
    }
    border-radius: 2px;
    background-color: ${primary};
    z-index: 1;
  }
`;

const StrongUnderline = (props: React.PropsWithChildren<unknown>) => (
  <StrongUnderlineWrapper>
    <span>{props.children}</span>
  </StrongUnderlineWrapper>
);

const JuicyText = StrongUnderline;

const GamesSection = styled(Section)`
  background: radial-gradient(#aaaaaa, #ffffff);
  padding-bottom: 16px;

  h2 {
    color: black;
    font-family: 'Tommy Soft';
    font-size: 32px;
    margin-bottom: 16px;
  }

  ${size.medium} {
    padding-bottom: 32px;
  }

  ${size.large} {
    padding-bottom: 32px;
  }
`;

const GamesGrid = styled.div`
  display: grid;

  grid-template-areas: 'featured';
  grid-template-columns: 1fr;
  grid-template-rows: 240px;
  grid-gap: 16px;

  ${size.medium} {
    grid-template-areas: 'featured featured';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 480px;
  }

  ${size.large} {
    grid-template-areas: 'featured featured featured';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 480px;
  }
`;

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Double Turn</title>
      </Helmet>
      <SiteHeader />
      <main>
        <HeroHeaderSection>
          <HeroHeaderTitle>
            Crafting great
            <br />
            games and apps
          </HeroHeaderTitle>
          <HeroHeaderSubtitle>Double Turn out now!</HeroHeaderSubtitle>
          <div>
            <Button
              href="https://store.steampowered.com/app/762680/Double_Turn/"
              icon={<img src={steamBlack} width="24" height="24" />}
            >
              Buy on Steam
            </Button>
          </div>
        </HeroHeaderSection>
        <AboutSection>
          <p>
            <strong>Inwave Labs</strong> is an indie game and product
            development studio. We craft <JuicyText>juicy games</JuicyText> that
            excite and <StrongUnderline>quality apps</StrongUnderline> that
            delight.
          </p>
          <div>
            <Button outline as={Link} to="/team">
              Read more
            </Button>
          </div>
        </AboutSection>
        <GamesSection>
          <h2>Games</h2>
          <GamesGrid>
            <GameLogoLink
              name="Double Turn"
              to="/double-turn"
              logo={logo}
              image={screenshot}
              css={{ gridArea: 'featured' }}
            />
          </GamesGrid>
        </GamesSection>
      </main>
      <SiteFooter />
    </>
  );
};

export default IndexPage;
